import React from "react"

import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
    <>
        <Seo
            title="404 page not found"
            description="Ooops, something is not right, which is an incredibly rare occurrence here at Opworks, so rare in fact that it is quite possibly priceless."
        />

        <PageTitle summary=""
                   title="404 page not found"/>

        <div className={"mb-10 lg:mb-32 px-6 md:px-12 mx-auto prose prose-lg"}>
            <p>Sorry, the page you are looking for cannot be found.</p>
            <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <p>If you are seeing this page after clicking a link on the site, please <Link to={'/contact'}>get in touch</Link> with us so we can fix the problem as soon as possible.</p>
        </div>
    </>
)

export default NotFoundPage
